import "./Shops.css";
import Footer from "../components/Footer/Footer";
import lounge from "../assets/lounge.jpeg";
import { useOutletContext } from "react-router-dom";
import { useEffect } from "react";
import ca from "../assets/ca.jpg";
import lcdh from "../assets/logo-lcdh-macau.png";
import lcdhmo from "../assets/lcdh.jpeg";
import ssw1 from "../assets/ssw1.jpeg";
import ssw2 from "../assets/ssw2.jpg";
import habanospecialist from "../assets/habanospecialist.jpeg";
import AOS from "aos";
import "aos/dist/aos.css";

const Shops = (props) => {
  const [language] = useOutletContext();
  const isChinese = language === "chinese";
  useEffect(() => {
    AOS.init({ delay: 100 });
    AOS.refresh();
  }, []);

  return (
    <>
      {/* <Cover pageNameChinese="門店位置" /> */}
      <div className="shops" style={{ backgroundImage: `url(${lounge})` }}>
        <div className="shops-container"></div>
      </div>
      <div>
        <div className="shops-bg">
          <div className="location" data-aos="fade-right">
            <h2>{isChinese ? "店舖及位置" : "Our Shops and Lounges"}</h2>
          </div>
          <div className="shop" data-aos="flip-up">
            <div className="shop-photo">{<img src={ca} alt="lcdh" />}</div>
            <div className="shop-detail">
              <div className="shopName">
                <h3>
                  {isChinese
                    ? "最新旗艦店（敬請期待...）"
                    : "Flagship Store (Coming Soon...)"}
                </h3>
              </div>

              <p>
                {isChinese
                  ? "地址：氹仔蓮花海演大馬路 2483號 商業（子部分A）地下R&S座"
                  : "Address: Na Taipa, Avenida Marginal Flor de Lotus no.2479, Comercio (Subcondominio A) Res-Do-Chao R&S"}
              </p>
              <p>
                {isChinese
                  ? "營業時間：敬請期待..."
                  : "Operating Hours: Coming Soon..."}
              </p>
              <p>{isChinese ? "電話：敬請期待..." : "Tel: Coming Soon..."}</p>
            </div>
          </div>
          <div className="shop" data-aos="flip-up">
            <div className="shop-photo">
              <img src={lcdhmo} alt="lcdh" />
            </div>
            <div className="shop-detail">
              <div className="shopName">
                <h3>La Casa del Habano Macau</h3>
                <a
                  href="http://www.lcdh.com.mo/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={lcdh} alt="lcdh-logo" />
                </a>
              </div>

              <p>
                {isChinese
                  ? "地址：澳門北京街117號地下i座 "
                  : "Address: Rua de Pequim No.117, Edificio de comercial de Macau, Res-Do-Chao I, Macau"}
              </p>
              <p>
                {isChinese
                  ? "營業時間：星期一至日早上9:00 - 淩晨2:30"
                  : "Operating Hours：Monday - Sunday, 9:00am to 2:30am"}
              </p>
              <p>{isChinese ? "電話：(853)2878320" : "Tel: (853)28788320"} </p>
            </div>
          </div>
          <div className="shop" data-aos="flip-up">
            <div className="shop-photo">
              <img src={ssw1} alt="ssw1" />
            </div>
            <div className="shop-detail">
              <div className="shopName">
                <h3>{isChinese ? "第一分店" : "San Son Wai Cigars"}</h3>
                <img src={habanospecialist} alt="lcdh-logo" />
              </div>

              <p>
                {isChinese
                  ? "地址：澳門十月一號前地131號 怡景閣地下T舖 "
                  : "Praceta de um de Outubro no:129-131 I Keng Res-Do-Chao T, Macau"}
              </p>
              <p>
                {isChinese
                  ? "營業時間：星期一至日24小時營業"
                  : "Operating Hours：Monday - Sunday, Opens all day"}
              </p>
              <p>{isChinese ? "電話：(853)28752720" : "Tel: (853)28752720"}</p>
            </div>
          </div>
          <div className="shop" data-aos="flip-up">
            <div className="shop-photo">
              <img src={ssw2} alt="ssw2" />
            </div>
            <div className="shop-detail">
              <div className="shopName">
                <h3>{isChinese ? "第二分店" : "San Son Wai Cigars"}</h3>
                <img src={habanospecialist} alt="lcdh-logo" />
              </div>

              <p>
                {isChinese
                  ? "地址：澳門倫斯泰特大馬路246-252號百德大廈地下D座"
                  : "Address: Avenida Sir Anders Ljungstedt No. 246-252, Pak Tak, Res-Do-Chao D"}
              </p>
              <p>
                {isChinese
                  ? "營業時間：星期一至日早上9:00 - 淩晨2:30"
                  : "Operating Hours：Monday - Sunday, 9:00am to 2:30am"}
              </p>
              <p>{isChinese ? "電話：(853)28785601" : "Tel: (853)28785601"}</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Shops;
